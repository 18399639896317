<script setup lang="ts">
import TICKETMEIMAGE from '@/assets/img/ticketme.png'

const { startDate, endDate, getEvents, getTotalEvents, limit } = useCalendarApiEventQuery({})

const { data: totalEvents, pending: isTotalLoading } = await getTotalEvents()
const { data: events, pending: isLoading } = await getEvents()

function selectStartDate(value: string) {
  limit.value = 6
  startDate.value = value
}

function selectEndDate(value: string) {
  limit.value = 6
  endDate.value = value
}

function showMore() {
  limit.value += 6
}

useHead({
  title: `TicketMe`,
  meta: [
    {
      hid: 'title',
      property: 'title',
      content: `TicketMe`
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: `TicketMe`
    },
    {
      hid: 'description',
      name: 'description',
      content: 'Покупайте билеты на мероприятия в Абхазии онлайн – TicketMe'
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: 'Покупайте билеты на мероприятия в Абхазии онлайн – TicketMe'
    },
    {
      hid: 'keywords',
      property: 'keywords',
      content:
        'Абхазия, Сухум, Гагра, Гудаута, Ткуарчал, Гал, Очамчыра, Гулрыпш, купить билеты, тикет ми, тикетми, тикетмибиз, расписание, афиша, билет, онлайн'
    },
    {
      hid: 'og:keywords',
      property: 'og:keywords',
      content:
        'Абхазия, Сухум, Гагра, Гудаута, Ткуарчал, Гал, Очамчыра, Гулрыпш, купить билеты, тикет ми, тикетми, тикетмибиз, расписание, афиша, билет, онлайн'
    },
    {
      hid: 'url',
      name: 'url',
      content: `https://ticketme.biz`
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `https://ticketme.biz`
    },
    {
      hid: 'image',
      name: 'image',
      content: `${TICKETMEIMAGE}`
    },
    {
      name: 'og:image',
      property: 'og:image',
      content: `${TICKETMEIMAGE}`
    }
  ]
})
</script>

<template>
  <NuxtLayout name="main-shop-layout">
    <section class="overflow-x-hidden">
      <t-stories-slider class="mb-2 md:mb-0" />
      <div>
        <t-horizontal-calendar
          class="px-4 xl:px-0 container"
          :start-date="startDate"
          :end-date="endDate"
          @select-start-date="selectStartDate"
          @select-end-date="selectEndDate"
        />
      </div>
      <div class="mt-6 pb-10 lg:pb-13">
        <t-list-events
          class=""
          :events="events"
          :totalEvents="totalEvents"
          :loader="isLoading"
          @show-more="showMore"
        />
      </div>

      <ClientOnly>
        <t-main-recommendations class="pb-5 lg:pb-12" />
        <!-- <t-bulletin-board class="pb-10 lg:pb-12" />
        <t-events-in-the-coming-days class="pb-10 lg:pb-12" /> -->
        <t-main-watched class="pt-5 pb-5 md:pb-0 md:pt-0" />
      </ClientOnly>
    </section>
  </NuxtLayout>
</template>
