<template>
  <div v-if="sliders && sliders.length" class="w-full h-130 lg:h-119" style="background: #f2f3f5">
    <div class="containerForSlider pb-2 container mySwiper1 overflow-hidden">
      <div class="swiper-wrapper">
        <t-slide
          v-for="slide in sliders"
          :key="slide.id"
          class="swiper-slide slide"
          :slide="slide"
        />
      </div>
      <div
        v-show="load"
        v-if="sliders.length > 1"
        class="w-5/12 flex justify-center m-auto mt-6 h-10"
      >
        <div
          v-for="(elm, index) in sliders"
          :key="index"
          style="height: 5px; overflow: hidden"
          class="w-[64px] rounded-lg mr-2 relative cursor-pointer"
          @click="toSlide(index)"
        >
          <div
            class="absolute w-[64px] rounded-xl"
            style="height: 5px; overflow: hidden; background-color: #c0d0e8"
          />

          <div
            class="absolute w-[64px] rounded-xl"
            style="height: 5px; background-color: #007aff"
            :style="index == key ? `width: ${percent}%` : key > index ? `width:64px` : `width:0%`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, Pagination } from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css'

import 'swiper/css/navigation'

const key = ref(0) // current slide
const percent = ref(0) // percent for story
const timer = ref(0)
const progress = ref(0)
const duration = ref(10000) // duration for every slide
const interval = ref(0)
const isPaused = ref(false)
const newDur = ref(0) // duration
const pausePer = ref(0) // pausePercent

const load = ref(false)

Swiper.use([Pagination])

const toSlide = (index: any) => {
  key.value = index
}

const { $get } = useApi()

const now = new Date()
const { data: sliders } = await useAsyncData(
  'get actual sliders',
  () =>
    $get(
      `/api/v1/slider?scheduleInfo.saleOpening_lte=${new Date(
        new Date(now)
      ).toISOString()}&scheduleInfo.saleEnding_gte=${new Date(
        new Date(now)
      ).toISOString()}&_sort=sort:asc`
    ),
  { default: () => [] }
)

onMounted(() => {
  const mySwiper1 = new Swiper('.mySwiper1', {
    direction: 'horizontal',
    freeMode: true,
    spaceBetween: 200,
    // loop: true,
    slidesPerView: 1,
    touchStartPreventDefault: false,
    speed: 1200,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 40
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 60
      },
      786: {
        slidesPerView: 1,
        spaceBetween: 120
      }
    }
  })

  mySwiper1.on('activeIndexChange', () => {
    key.value = mySwiper1.activeIndex
    reset()
  })

  watch(key, () => {
    mySwiper1.slideTo(key.value)
    reset()
  })

  const next = () => {
    if (key.value >= sliders.value.length - 1) {
      reset()
    } else {
      key.value++
      mySwiper1.slideNext()
    }
  }

  const prev = () => {
    if (key.value <= sliders.value.length - 1) {
      key.value = 0
      reset()
    } else {
      key.value--
      mySwiper1.slidePrev()
    }
  }

  const autoPlay = () => {
    if (key.value >= sliders.value.length - 1) {
      key.value = 0
      mySwiper1.slideTo(0)
      // newDur.value = 0
    } else {
      key.value++
      pausePer.value = 0
      newDur.value = 10000
      mySwiper1.slideNext()
    }
    reset()
  }

  const play = () => {
    timer.value = new Date().getTime()
    progress.value = window.setInterval(() => {
      // forward
      const time = new Date().getTime()
      if (newDur.value > 0) {
        percent.value = pausePer.value + Math.floor((100 * (time - timer.value)) / duration.value)
      } else {
        percent.value = Math.floor((100 * (time - timer.value)) / duration.value)
      }
    }, duration.value / 100)
    if (newDur.value > 0) {
      interval.value = window.setInterval(autoPlay, newDur.value)
    } else {
      interval.value = window.setInterval(autoPlay, duration.value)
    }
  }

  const reset = () => {
    percent.value = 0
    clearInterval(interval.value)
    clearInterval(progress.value)

    play()
  }

  const pauseStory = () => {
    isPaused.value = true
    pausePer.value = percent.value

    clearInterval(interval.value)

    clearInterval(progress.value)
    // calcul new duration
    newDur.value = duration.value - (pausePer.value * duration.value) / 100
  }
  const playStory = () => {
    isPaused.value = false
    play()
  }

  // //  Раскоментировать для получения функции паузы при удежрании (Бета-версия, работает дерьмово)

  // const slide = document.getElementsByClassName('slide');
  // console.log(slide)
  // for(let item of slide){
  //   item.addEventListener('mousedown',()=>{
  //   console.log('mousedown: pauseStory ')
  //   pauseStory();
  // },true);
  // item.addEventListener('mouseup', ()=>{
  //   console.log('mouseup: play');
  //   console.log('pausePer:', pausePer.value, 'newDur:', newDur.value, 'percent:', percent.value)
  //   playStory()
  // },true)
  // }

  play()
  load.value = true
  return { next, prev, pauseStory, playStory, toSlide }
})

// const delay = async () => {
//   if (sliders.value[0].image.uploadURL) {
//   setTimeout(() => {
//       load.value = true
//     }, 100)
//   }
// }

// if (sliders) {
//   delay()
// }
</script>
