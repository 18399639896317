<template>
  <div class="mt-6 px-4 xl:px-0 w-full">
    <NuxtLink draggable="false" :to="`/activities/${slide.activity.id}?eventId=${slide.event.id}`">
      <div class="max-w-300 h-111 lg:h-100 flex flex-col-reverse lg:flex-row ticket">
        <div
          ref="leftC"
          class="h-35 lg:h-full w-full lg:w-101 bg-white flex flex-col rounded-b-[27px] lg:rounded-b-[0px] lg:rounded-l-[27px] px-0 desktop:px-10 pl-8 lg:justify-between"
          :class="isClicked ? 'animation1' : ''"
          @click="isClicked = true"
        >
          <div class="flex flex-row justify-between mt-9 lg:mt-10 pr-4 user-select-none">
            <div
              ref="titleCard"
              class="titleCard font-sf-pro text-lg lg:text-4xl text-gray-900 font-semibold"
            >
              {{ slide.activity.title }}
            </div>
          </div>
          <div class="lg:mt-9 font-sf-pro text-lg text-gray-300 pr-4 hidden lg:block">
            {{ slide.activity.snippet }}
          </div>
          <div class="mt-2 lg:mt-10 pb-8 lg:pb-12 pr-4">
            <div
              v-if="slide.event"
              :class="height < 40 ? 'titleCardPlaceNameV1' : 'titleCardPlaceNameV2'"
              class="text-sm lg:text-base mb-2 lg:mb-5 text-gray-300 font-sf-pro"
            >
              {{ slide.event.place.name }}
            </div>
            <div class="flex flex-row justify-between">
              <div
                class="flex flex-col space-y-1 lg:space-y-3 font-sf-pro text-gray-500 text-lg w-48"
              >
                <div class="flex lg:flex-row space-x-2 items-center">
                  <div
                    v-if="slide.event && slide.event.scheduleInfo.isRegular"
                    class="font-semibold whitespace-nowrap font-sf-pro text-gray-300 text-sm lg:text-xl"
                  >
                    {{ formatEventDuration(slide.event.allDates) }}
                  </div>
                  <div
                    v-else-if="slide.event"
                    class="font-semibold whitespace-nowrap font-sf-pro text-gray-300 text-sm lg:text-xl"
                  >
                    {{ formatDate(slide.event.scheduleInfo.sessionDate, 'D MMMM') }}
                  </div>
                  <div class="text-base text-gray-300 font-sf-pro font-bold hidden mt-1 lg:block">
                    &#183;
                  </div>
                  <div class="text-sm lg:text-base text-gray-300 font-sf-pro hidden mt-1 lg:block">
                    {{ slide.activity.ageLimit }}
                  </div>
                </div>
              </div>
              <div
                class="lg:w-full w-40 font-sf-pro text-primary-100 text-base lg:text-xl self-end font-semibold flex justify-end"
                v-if="slide.event.minPrice"
              >
                от {{ slide.event.minPrice }} ₽
              </div>
              <div
                class="font-semibold text-primary-100 text-base lg:text-lg font-sf-pro"
                v-else-if="slide.event.tariff.type === 'free'"
              >
                Бесплатно
              </div>
            </div>
          </div>
        </div>
        <div
          style="background-size: cover; background-position: center"
          class="rightC h-76 w-full lg:h-100 lg:w-213 rounded-t-[27px] lg:rounded-l-[0px] lg:rounded-r-[27px]"
          :data-blink-src="rewriteImageUrl(slide.image.uploadURL)"
        ></div>
        <!-- <div
          :style="`background-image: image-set(
            url('https://5a084c8fdde49694365a.ucr.io/-/scale_crop/812x400/center/-/format/auto/-/quality/better/${slide.image.uploadURL}') 1x,
            url('https://5a084c8fdde49694365a.ucr.io/-/scale_crop/1624x800/center/-/format/auto/-/quality/better/${slide.image.uploadURL}') 2x
          )`"
          style="background-size: cover; background-position: center"
          class="rightC h-76 w-full lg:h-100 lg:w-213 rounded-t-[27px] lg:rounded-l-[0px] lg:rounded-r-[27px]"
        /> -->
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { formatEventDuration } from '../helpers/formatEventDuration'
import { shallowRef, ref } from 'vue'
import rewriteImageUrl from '~/utils/rewrite-image-url'

const { formatDate } = useDate()
const titleCard = ref()
const height = ref(20)
onMounted(() => {
  height.value = titleCard.value.offsetHeight
  console.log('height', height.value)
})

const leftC = shallowRef()

const props = defineProps({
  slide: {
    type: Object,
    default: () => {}
  }
})
</script>

<style scoped>
.ticket {
  position: relative;
}

.titleCard {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}

.titleCardPlaceNameV1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}

@media (min-width: 640px) {
  .titleCardPlaceNameV2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }
}

@media (max-width: 640px) {
  .titleCardPlaceNameV2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
  }
}

/* .titleCardPlaceName:hover{
  overflow: inherit;
  text-overflow: inherit;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  animation-duration: 300ms;
  transition: ease-in-out;
  transition-duration: 300ms;

} */

.rightC {
  position: relative;
  border-radius: 0px 27px 27px 0px;
}

/* .rightC.active{
  animation: myright 1s;

} */

/* @keyframes myright {
  0% {transform: translate(0, 0px) rotate(0deg);}
  50% {transform: translate(60px, 60px) rotate(10deg);}
  100% {transform: translate(0, 0px) rotate(0deg);}
} */

.rightC::before {
  background: #f3f4f5;
  border-radius: 50%;
  box-shadow: 0 400px 0 0 #f3f4f5, inset 0 -12px 5px -5px #00000010, 0 65px 0 -10px #e4e5e6,
    0 95px 0 -10px #e4e5e6, 0 125px 0 -10px #e4e5e6, 0 155px 0 -10px #e4e5e6,
    0 185px 0 -10px #e4e5e6, 0 215px 0 -10px #e4e5e6, 0 245px 0 -10px #e4e5e6,
    0 275px 0 -10px #e4e5e6, 0 305px 0 -10px #e4e5e6, 0 335px 0 -10px #e4e5e6;
  content: '';
  display: block;
  height: 40px;
  left: -20px;
  position: relative;
  top: -20px;
  width: 40px;
}

.leftC {
  position: relative;
  background: white;
  border-radius: 27px 0px 0px 27px;
}

.leftC::before {
  background: #f3f4f5;
  border-radius: 50%;
  box-shadow: 0 400px 0 0 #f3f4f5, inset 0 -10px 10px 0 #00000010, 0 65px 0 -10px #e4e5e6,
    0 95px 0 -10px #e4e5e6, 0 125px 0 -10px #e4e5e6, 0 155px 0 -10px #e4e5e6,
    0 185px 0 -10px #e4e5e6, 0 215px 0 -10px #e4e5e6, 0 245px 0 -10px #e4e5e6,
    0 275px 0 -10px #e4e5e6, 0 305px 0 -10px #e4e5e6, 0 335px 0 -10px #e4e5e6;
  content: '';
  display: block;
  height: 40px;
  left: 385px;
  position: absolute;
  top: -20px;
  width: 40px;
}

/*
.leftC.active{
  animation: mymove 1s;

} */

/* @keyframes mymove {
  0% {transform: translate(0, 0px) rotate(0deg);}
  50% {transform: translate(-60px, 45px) rotate(-10deg);}
  100% {transform: translate(0, 0px) rotate(0deg);}
} */

/* .leftC:hover{
    transform: translate(-40px, 35px) rotate(-10deg);
} */

@media screen and (min-width: 1023px) {
  .leftC {
    box-shadow: -10px 0px 10px 0 #0000000f;
  }

  .rightC {
    box-shadow: 5px 0px 10px 0 #00000010;
  }
}

@media screen and (max-width: 1023px) {
  .leftC {
    border-radius: 0px 0px 27px 27px;
    box-shadow: 0 3px 10px 0px #0000000f;
  }

  .leftC::before {
    transform: rotate(-90deg);
    left: -20px;
    top: -20px;
    box-shadow: 0 770px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6,
      0 595px 0 -10px#e4e5e6, 0 625px 0 -10px#e4e5e6, 0 655px 0 -10px#e4e5e6, 0 685px 0 -10px#e4e5e6;
  }

  .rightC {
    border-radius: 27px 27px 0px 0px;
    box-shadow: 0 -3px 10px 0 #0000000f;
  }

  .rightC::before {
    transform: rotate(-90deg);
    top: 285px;
    left: -20px;
    box-shadow: 0 735px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6,
      0 595px 0 -10px#e4e5e6, 0 625px 0 -10px#e4e5e6, 0 655px 0 -10px#e4e5e6, 0 685px 0 -10px#e4e5e6;
  }
}

/* @media screen and (max-width: 767px) {
  .leftC::before {
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6;
  }

  .rightC::before {
    top: 285px;
    left: -25px;
    box-shadow: 0 615px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6;
  }
} */

@media screen and (max-width: 767px) {
  .leftC::before {
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6;
  }

  .rightC::before {
    top: 285px;
    left: -25px;
    box-shadow: 0 615px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6, 0 385px 0 -10px#e4e5e6, 0 415px 0 -10px#e4e5e6, 0 445px 0 -10px#e4e5e6,
      0 475px 0 -10px#e4e5e6, 0 505px 0 -10px#e4e5e6, 0 535px 0 -10px#e4e5e6, 0 565px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 639px) {
  .leftC::before {
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6;
  }

  .rightC::before {
    top: 285px;
    left: -25px;
    box-shadow: 0 400px 0 0 #f3f4f5, 0 55px 0 -10px#e4e5e6, 0 85px 0 -10px#e4e5e6,
      0 115px 0 -10px#e4e5e6, 0 145px 0 -10px#e4e5e6, 0 175px 0 -10px#e4e5e6, 0 205px 0 -10px#e4e5e6,
      0 235px 0 -10px#e4e5e6, 0 265px 0 -10px#e4e5e6, 0 295px 0 -10px#e4e5e6, 0 325px 0 -10px#e4e5e6,
      0 355px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 424px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 400px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6, 0 300px 0 -10px#e4e5e6, 0 330px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 420px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 390px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6, 0 300px 0 -10px#e4e5e6, 0 330px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 396px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 362px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6, 0 300px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 375px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 349px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6, 0 300px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 370px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 670px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 342px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 360px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 660px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 332px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 356px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 322px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6, 0 270px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 327px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 293px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6,
      0 240px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 300px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 266px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6, 0 210px 0 -10px#e4e5e6;
  }
}

@media screen and (max-width: 273px) {
  .leftC::before {
    top: -20px;
    left: -25px;
    box-shadow: 0 650px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6;
  }

  .rightC::before {
    left: -25px;
    top: 285px;
    box-shadow: 0 239px 0 0 #f3f4f5, 0 60px 0 -10px#e4e5e6, 0 90px 0 -10px#e4e5e6,
      0 120px 0 -10px#e4e5e6, 0 150px 0 -10px#e4e5e6, 0 180px 0 -10px#e4e5e6;
  }
}
</style>

<style>
/*
.ticket-border {
  border-right: 19px dotted #f2f3f5;
  top: 0px;
  -moz-top: 15px;
  bottom: 10px;
  left: 373px;
}

@-moz-document url-prefix(){
  .ticket-border{
    top: 20px;
  }
} */

/* .ticket:after,
.ticket::before {
  content: '';
  position: absolute;
  display: block;
  width: 34px;
  height: 34px;
  background: #f2f3f5;
  border-radius: 50%;
  left: 387px;
}

.ticket:before {
  top: -0.9em;
}

.ticket:after {
  bottom: -0.9em;
}

@media screen and (max-width: 1199px) {
  .ticket-border {
    border-right: 19px dotted #f2f3f5;
    top: 0px;
    bottom: 10px;
    left: 315px;
  }

  .ticket:after,
  .ticket::before {
    left: 329px;
  }

  @-moz-document url-prefix(){
  .ticket-border{
    top: 20px;
  }
}
}

@media screen and (max-width: 1024px) {
  .ticket-border {
    border-top: 20px dotted #f2f3f5;
    top: 296px;
    bottom: 10px;
    left: 0;
    border-right: 0;
  }

  .ticket::before {
    left: -12px;
    top: 286px;
  }

  .ticket:after {
    left: inherit;
    right: -12px;
    top: 286px;
  }
} */
</style>
